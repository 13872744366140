.whitepaper {
    margin-top: 150px;
}
.whitepaper section {
    background: #0b0b0b;
    color: white;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .whitepaper h1 {
    font-size: 24px;
    margin-bottom: 10px;
    border-bottom: 2px solid #313131;
    padding-bottom: 10px;
  }
  
  .whitepaper h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .whitepaper h3 {
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  .whitepaper .section-title {
    font-size: 1.75rem;
    border-bottom: 1px solid #313131;
    padding-bottom: 5px;
    margin-bottom: 20px;
  }
  
  .stats p {
    margin-bottom: 5px;
  }
  
  .whitepaper p {
    margin-bottom: 15px;
    line-height: 1.8;
    opacity: 0.7;
  }
  
  .whitepaper strong {
    color: #ffffff;
  }
