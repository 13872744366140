
footer p,

footer a {
  color: white !important;
  text-decoration: none;
  opacity: 0.6;
  width: fit-content;
}

footer .brand img {
  margin: 0;
}

footer a:hover {
  opacity: 1;
  background: -webkit-linear-gradient(left, #2e27ff 0%, #7c1fff 100%);
  background: linear-gradient(to right, #2e27ff 0%, #7c1fff 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.border-top {
  border-top: 1px solid rgb(27, 27, 27) !important;
}

@media (max-width: 1199.98px) {}

@media (max-width: 991.98px) {
  .second {
    display: none;
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 25%;
  }
}

@media (max-width: 575.98px) {
  footer .container {
    margin: 0px !important;
    padding: 0px !important;
    text-align: center;
  }

  footer .py-5 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }

  footer .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 100%;
  }

  footer .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    align-content: center;
    align-items: center;
  }

  .copy-footer {
    width: 100%;
  }

  .nav-bottom .brand img,
  .nav-bottom .container {
    margin: 0px !important;
    padding: 0px !important;
  }

  .nav-bottom {
    margin: 0 20px;
  }

  h5 {
    margin-top: 20px !important;
  }
}