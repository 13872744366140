.converter {
    width: 100vw;
    max-height: fit-content;
    margin-top: 100px;
    padding-bottom: 100px;
    background-color: black;
}

.content-conv {
    display: flex;
}

.conv-side-left, 
.conv-side-right {
    width: 50%;
    padding-top: 150px;
}

.conv-side-right h1 {
    font-size: 32px;
    font-weight: 300;
    margin-bottom: 40px;
    line-height: 34px;
}

.conv-side-left {
    padding-right: 80px;
}

gecko-coin-converter-widget {
    opacity: 0.9;
}

.conv-side-left video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
}


@media (max-width: 575.98px) {
    .converter {
        padding-top: 250px;
    }

    .content-conv {
        display: flex;
        flex-direction: column;
    }

    .conv-side-left, 
    .conv-side-right {
        width: 100%;
        padding: 0 20px;
    }

    .conv-side-left {
        display: none;
    }

    .conv-side-right  {
        text-align: center;
    }

}
