nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  padding-top: 40px !important;
}

.left-nav,
.right-nav {
  display: flex;
  align-items: center;
}

.left-nav img {
  margin-right: 6px;
}

nav a {
  color: white;
  text-decoration: none;
}

nav a:hover {
  text-decoration: none;
  color: white;
}

nav a div {
  display: flex;
  align-items: center;
}

.sign-in {
  margin-right: 0px !important;
}

.brand a {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 900;
}

.brand img {
  width: 28px;
  height: 28px;
  margin-right: 12px;
}

.xadrez {
  margin-left: 20px;
}

.menu {
  width: 24px;
  height: 24px;
  margin-right: 30px;
  display: none;
}

.title {
  margin-top: 40px;
  margin-bottom: 12px;
  text-transform: uppercase;
  font-weight: 500;
}

.box-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box-link div {
  display: flex;
  align-items: center;
}

.box-link p {
  padding-left: 8px;
}

.offcanvas-body a:-webkit-any-link {
  color: white;
  cursor: pointer;
  text-decoration: none;
}

#offcanvasMenu {
  background-color: rgb(18, 18, 18);
  width: 100vw;
}

.offcanvas-header {
  padding: 40px 20px;
}

.offcanvas-body {
  padding: 0px 20px;
}

.btn-close-white {
  filter: invert(1);
  opacity: 1;
}

.divider {
  margin: 0 12px;
}

.sign-in {
  background: -webkit-linear-gradient(left, #2e27ff 0%, #7c1fff 100%);
  background: linear-gradient(to right, #2e27ff 0%, #7c1fff 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 991.98px) {
  .xadrez,
  .sign-in,
  .sign-up,
  .comentario,
  .premium,
  .divider,
  .porcentagem {
    display: none;
  }

  .menu {
    display: block;
  }

  .menu {
    margin-right: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .brand img {
    margin: 0px 12px 0px 0px;
  }
}

@media (max-width: 575.98px) {
  .xadrez,
  .sign-in,
  .sign-up,
  .comentario,
  .premium,
  .divider,
  .porcentagem {
    display: none;
  }

  nav {
    padding: 40px 20px 40px 20px !important;

  }

  .menu {
    margin-right: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .brand img {
    margin: 0px 12px 0px 0px;
  }
}
