.main {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  color: white;
}

.ct-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: black;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.2;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content h2 {
  font-size: 42px;
  font-weight: 100;
  letter-spacing: -2px;
}

.content h1 {
  font-size: 42px;
  font-weight: 100;
  margin-top: 12px;
  letter-spacing: -2px;
  opacity: 0.5;
}

.content .legend {
  width: 450px;
  font-weight: 100;
  line-height: 20px;
  text-align: center;
  letter-spacing: 2px;
  margin-top: 24px;
  font-size: 16px;
  opacity: 0.5;
}

.content .explore p {
  margin: 0;
  padding: 0;
  margin-right: 4px;
}


.content .explore .explore-bar {
  padding: 0 12px;
}

.content .explore {
  display: flex;
  align-items: center;
  padding: 6px 20px;
  border: solid 1px rgba(255, 255, 255, 0.243);
  border-radius: 100px;
  margin-bottom: 24px;
}

.content .explore .explore-select {
  background: -webkit-linear-gradient(left, #2e27ff 0%, #7c1fff 100%);
  background: linear-gradient(to right, #2e27ff 0%, #7c1fff 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.content .explore img,
.content .explore .explore-select {
  cursor: pointer;
}

.content .explore img {
  width: 20px;
  height: 20px;
}

.btn-invest {
  background-color: white;
  padding: 14px 25px;
  border-radius: 50px;
  color: black;
  display: flex;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  margin-top: 40px;
}

.btn-invest p:hover {
  background: -webkit-linear-gradient(left, #2e27ff 0%, #7c1fff 100%);
  background: linear-gradient(to right, #2e27ff 0%, #7c1fff 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-invest img {
  width: 12px;
  height: 12px;
  margin-left: 6px;
}

@media (max-width: 1199.98px) {}

@media (max-width: 991.98px) {
  .main {
    padding: 0 40px;
    text-align: center;
  }
  .content h2,
  .content h1 {
    font-size: 32px;
  }
}

@media (max-width: 575.98px) {
  .main {
    padding: 0 20px;
  }
  
  .content {
    text-align: center;
  }

  .content h2 {
    opacity: 0.6;
    font-size: 20px;
    letter-spacing: -1px;
  }
    
  .content h1 {
    margin-top: 12px;
    font-size: 32px;
    opacity: 1;
  }

  .content .legend {
    width: 100%;
  }

  .btn-invest {
    padding: 16px 28px;
  }
}
