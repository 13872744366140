.ct-coin-info {
    width: 100%;
    height: 100px;
    position: absolute;
    background-color: black;
}

.info-coin {
    display: flex;
    justify-content: space-around;
    margin-top: -50px;
    background-color: rgba(16, 16, 16, 0.78);
    border-radius: 20px;
    padding: 40px 0;
}

.info-coin h1 {
    font-size: 16px;
    padding-bottom: 6px;
    color: rgba(255, 255, 255, 0.503);
}

@media (max-width: 991.98px) {
    .info-coin {
        display: flex;
        flex-wrap: wrap;
        height: 150px;
        justify-content: space-around;
        align-items: center;
        background-color: white;
        color: black;
        margin-top: -100px !important;
        padding: 10px 15px;
        margin-bottom: 10px;
        flex-direction: row;
        text-align: center;
    }
    .info-coin h1 {
        font-size: 16px;
        font-weight: 900;
        padding-bottom: 6px;
        color: black;
    }
}

@media (max-width: 575.98px) {
    .ct-coin-info {
        width: 100%;
        height: fit-content;
        margin-top: -1px;
        padding: 0 20px;
    } 

    .info-coin {
        display: flex;
        height: 300px;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        background-color: white;
        color: black;
        margin-top: -100px !important;
        padding: 10px 15px;
        margin-bottom: 10px;
        text-align: left;
    }

    .info-coin h1 {
        font-size: 16px;
        font-weight: 900;
        padding-bottom: 6px;
        color: black;
        text-align: left;
    }

    .converter .container {
        margin: 0 !important;
        padding: 0 !important;
    }
}