.roadmap {
    width: 100vw;
    background-color: black;
    margin-top: -1px;
    padding: 150px 40px;
}

.roadmap-ct {
    display: flex;
    justify-content: space-between;
}

.roadmap-ct div {
    width: 325px;
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.roadmap-ct .roadmap-info {
    width:fit-content;
    padding: 0px 0px !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.roadmap-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 150px;
} 

.roadmap-title h1 {
    width: fit-content;
    font-size: 42px;
    font-weight: 300;
    background: -webkit-linear-gradient(left, #2e27ff 0%, #7c1fff 100%);
    background: linear-gradient(to right, #2e27ff 0%, #7c1fff 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.roadmap-title p {
    width: 600px;
    text-align: center;
    font-size: 24px;
    font-weight: 300;
    margin-top: 16px;
    opacity: 0.7;
    letter-spacing: 1px;
}

.roadmap-info {
    margin-bottom: -4px;
}

.roadmap-info img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
}

.roadmap-ct p {
    margin-top: 12px;
    letter-spacing: 1px;
    font-weight: 300;
    line-height: 16px;
    opacity: 0.7;
}

.roadmap-ct h1 {
    font-size: 24px;
    font-weight: 300;
    margin-top: 42px;
}

.roadmap-ct h2 {
    font-size: 28px;
    font-weight: 300;
}


.roadmap-ct .info-done h2 {
    font-size: 28px;
    font-weight: 900;
}

@media (max-width: 1199.98px) {}

@media (max-width: 991.98px) {}

@media (max-width: 575.98px) {
    .roadmap-title p {
        width: 95%;
        font-size: 16px;
    }

    .roadmap-ct {
        display: flex;
        flex-direction: column;
    }

    .roadmap {
        width: 100vw;
        padding: 0 20px;
        padding-top: 100px;
        padding-bottom: 150px;
    }

    .roadmap-ct div {
        width: 100%;
        padding: 0px;
        margin-bottom: 80px;
        align-items: center;
        text-align: center;
    }

    .roadmap-ct .roadmap-info {
        width:fit-content;
        margin-bottom: 0;
    }
}