.about-coin {
    padding: 100px 20px;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -1px;
}

.about-coin h1 {
    width: fit-content;
    height: 78px;
    padding-top: 3px;
    font-size: 42px;
    font-weight: 300;
    line-height: 34px;
    background: -webkit-linear-gradient(left, #2e27ff 0%, #7c1fff 100%);
    background: linear-gradient(to right, #2e27ff 0%, #7c1fff 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}

.about-coin p {
    width: 45%;
    text-align: center;
    letter-spacing: 1px;
    font-weight: 300;
    line-height: 18px;
    margin-top: 40px;
}

@media (max-width: 1199.98px) {
    .about-coin p {
        width: 65%;
    }
}

@media (max-width: 991.98px) {
    .about-coin p {
        width: 80%;
    }
}

@media (max-width: 575.98px) {
    .about-coin p {
        width: 100%;
        text-align: center;
        margin-top: 0px;
    }

    .about-coin h1 {
        width: 100%;
        height: 120px;
        padding-top: 3px;
        text-align: center;
    }

    .about-coin {
        padding: 50px 20px;
    }
}