.pattern-container {
    width: 100vw;
    padding: 150px 40px;
    display: flex;
    margin-top: -1px;
    background-color: black;
}

.world-bkg,
.content-pattern {
    width: 50vw;
}

.world-bkg {
    height: 550px;
    background-image: url(../../../../assets//img/world.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.content-pattern {
    padding-top: 200px;
}

.pattern-title {
    margin-bottom: 80px;
}

.content-pattern h1 {
    width: fit-content;
    font-size: 42px;
    font-weight: 300;
}

.content-pattern h2 {
    font-size: 20px;
    font-weight: 300;
    padding-left: 8px;
}

.content-pattern p {
    width: 70%;
    margin-top: 12px;
    letter-spacing: 1px;
    font-weight: 300;
    line-height: 16px;
    opacity: 0.7;
}

.content-pattern div div {
    display: flex;
    align-items: center;
}

.content-pattern div {
    margin-top: 32px;
}

@media (max-width: 1199.98px) {
    .content-pattern {
        padding-left: 40px;
    }

    .content-pattern p {
        width: 100%;
    }

    .pattern-container {
        padding: 80px 40px;
    }
}

@media (max-width: 991.98px) {
    .pattern-container {
        width: 100vw;
        padding: 80px 40px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 0px;
    }

    .world-bkg,
    .content-pattern {
        width: 100vw;
    }

    .world-bkg {
        height: 400px;
    }

    .content-pattern {
        padding: 0;
        padding-top: 100px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .content-pattern h2,
    .content-pattern h1 {
        width: fit-content;
    }

    .content-pattern div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
    }

    .content-pattern div div img {
        width: 32px;
        height: 32px;
        margin-bottom: 16px;
    }

}

@media (max-width: 575.98px) {
    .world-bkg {
        height: 350px;
    }
}