.source-ct {
    width: 100vw;
    background-color: black;
    margin-top: -1px;
    padding: 150px 40px;
    display: flex;
    flex-direction: row-reverse;
}

.open-source,
.source-content {
    width: 50vw;
}

.open-source {
    height: 500px;
    padding: 24px;
    overflow: scroll;
    background-color: rgb(11, 11, 11);
    border-radius: 10px;
    font-weight: 300;
    font-family: "Courier New", Courier, monospace;
}

.open-source code {
    display: block;
}

.source-content {
    padding-right: 40px;
    padding-top: 100px;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.source-content h1 {
    font-size: 42px;
    font-weight: 300;
}

.source-content p {
    letter-spacing: 1px;
    font-weight: 300;
    line-height: 16px;
    opacity: 0.7;
}

.source-content .source-code {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 10px 25px;
    border: solid 1px rgba(255, 255, 255, 0.243);
    border-radius: 100px;
    margin-bottom: 24px;
    background: -webkit-linear-gradient(left, #2e27ff 0%, #7c1fff 100%);
    background: linear-gradient(to right, #2e27ff 0%, #7c1fff 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    margin-top: 40px;
}

.source-content .source-info {
    margin-top: 24px;
    margin-bottom: 32px;
}

.source-content .founders {
    margin-top: 32px;
}


@media (max-width: 1199.98px) {}

@media (max-width: 991.98px) {
    .source-ct {
        flex-direction: column;
        padding: 0px 20px;
        padding-top: 100px;
        padding-bottom: 150px;
    }

    .open-source,
    .source-content {
        width: 100%;
    }

    .source-content {
        padding-right: 0px;
        text-align: center;
        align-items: center;
    }

    .source-content .source-code {
        padding: 15px 40px;
    }
}

@media (max-width: 575.98px) {
    .source-ct {
        flex-direction: column;
        padding: 0px 20px;
        padding-top: 0px;
        padding-bottom: 150px;
    }
}